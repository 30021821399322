import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Detail } from '../common/DetailPage';
import { useTranslation } from 'react-i18next';
import Card from '@cxeweb/pattern-library/lib/components/card';
import Button from '@cxeweb/pattern-library/lib/components/button';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import Images from '../../images';
import { CAM_SCANNER_PATH, EXPLORE_OVERVIEW_PATH, getHomePath } from '../../constants/paths.js';
import { ai } from '../../telemetryService';
import AICustomEvents from '../../constants/appInsightEvents';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../common/Footer';
import { Modal } from 'react-responsive-modal';
import seeJourney from '../common/TileCarousel/seeJourney.mp4';
import seeJourneyFr from '../common/TileCarousel/seeJourney-fr.mp4';
import { getProductDetailPath } from '../../constants/paths';

const WelcomeView = () => {
    ai.trackEvent({ name: AICustomEvents.welcomePage });
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const language = useSelector((state) => state.translation.language);
    const navigate = useNavigate();
    const videoSrc = language === 'fr' ? seeJourneyFr : seeJourney;

    const onVideoEnd = () => {
        // const finishedLots = [
        //     'YRP486544', // newSku:11130112 oldSKU:11028472-2025-02-02 espresso
        //     'YRP486946', //newSku:11130110, oldSKU:11028479-2025-02-25 sumatra
        // ];
        // const random = finishedLots[Math.floor(Math.random() * finishedLots.length)];
        // setOpen(false);
        // navigate(getHomePath(random));

        const seeAnotherSku = ['11162824', '11162822'];
        const random = seeAnotherSku[Math.floor(Math.random() * seeAnotherSku.length)];
        setOpen(false);
        navigate(getProductDetailPath(random));
    };

    return (
        <>
            <Detail title={t('welcome.traceYourCoffee')} backTitle=' '>
                <div className={'mainBodyArea'}>
                    <div className='app-containerArea'>
                        <Card
                            disableBoxShadow
                            imagePosition='top'
                            imageUrl={Images.mapJourney}
                            contentClasses='pl3 pr3 pt4 pb2'
                        >
                            <Heading tagName='h4' size={'xs'} className='text-bold mb3'>
                                {t('welcome.coffeeJourney.header')}
                            </Heading>

                            <p className='text-xxs mb3'>{t('welcome.coffeeJourney.text')}</p>
                            <img src={Images.batchCodeLocation} alt='batch code location' />
                            <p className='text-xxs mb3 mt3'>{t('welcome.locateBatchCode')}</p>
                            <div className='text-center mb3 pt3 pb3'>
                                <Button
                                    visualStyle='positive'
                                    className='pl6 pr6 pt2 pb2'
                                    onClick={() => navigate(CAM_SCANNER_PATH)}
                                >
                                    {t('welcome.traceYourCoffee')}
                                </Button>
                            </div>
                            <p className='text-xxs text-center mb1 mt3'>{t('welcome.notHaveABag')}</p>
                            <div className='text-center mb3 pt1 pb3'>
                                <Button className='pl6 pr6 pb2' onClick={() => setOpen(true)}>
                                    {t('welcome.seeAJourney')}
                                </Button>
                            </div>
                        </Card>
                        <Card
                            disableBoxShadow
                            imagePosition='top'
                            imageUrl={Images.exploreOverviewGrow}
                            contentClasses='pl3 pr3 pt4 pb2'
                        >
                            <Heading tagName='h4' size={'xs'} className='text-semibold mb3'>
                                {t('welcome.explore.header')}
                            </Heading>
                            <p className='text-xxs mb3 mt3'>{t('welcome.explore.text')}</p>
                            <div className='text-center mb3 pt3 pb3'>
                                <Button
                                    visualStyle='positive'
                                    className='pl6 pr6 pt2 pb2'
                                    onClick={() => navigate(EXPLORE_OVERVIEW_PATH)}
                                >
                                    {t('welcome.explore.button')}
                                </Button>
                            </div>
                        </Card>
                        <Card imagePosition='top' imageUrl={Images.plantCoffeeTree} />
                        <Card
                            style={{ marginTop: '-77px', position: 'relative' }}
                            contentClasses='pl3 pr3 pt4 pb2'
                            containerClasses={'ml2 mr2 mb4'}
                        >
                            <Heading tagName='h4' size={'xs'} className='text-semibold mb3'>
                                {t('welcome.tracebility.header')}
                            </Heading>
                            <p className='text-xxs mb3'>{t('welcome.tracebility.text')}</p>
                            <div className='text-center mb3'>
                                <a
                                    href='https://stories.starbucks.com/stories/2020/new-starbucks-traceability-tool-explores-bean-to-cup-journey/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <Button visualStyle='positive' className='pl6 pr6 pt2 pb2'>
                                        {t('welcome.tracebility.button')}
                                    </Button>
                                </a>
                            </div>
                        </Card>
                        <Footer />
                    </div>
                </div>
            </Detail>
            <Modal
                open={open}
                center
                classNames={{
                    overlay: 'videoOverlay',
                    modal: 'videoModal',
                }}
                onClose={() => setOpen(false)}
            >
                <video width='320' height='240' autoPlay={true} playsInline muted onEnded={() => onVideoEnd()}>
                    <source src={videoSrc} type='video/mp4' />
                    Your browser does not support the video tag or the file format of this video.
                </video>
            </Modal>
        </>
    );
};

export default WelcomeView;
